import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const AlbumTemplate = ({
  helmet,
  featuredimage,
  title,
  caption,
  album,
  tanggal,
  categories = [],
}) => {

  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <h1 className="title is-size-2 has-text-weight-bold is-bold-light" style={{ textAlign: 'center' }}>
             Album: {title}
        </h1>
        <div style={{ maxWidth: '700px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            <div>
              {featuredimage &&
                <PreviewCompatibleImage
                  imageInfo={{
                    image: featuredimage,
                    alt: title,
                  }}
                />
              }
              {album && album.map(album => (
                <div className="section">
                  <h2>{album.deskripsi}</h2>
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: album.image,
                      alt: album.deskripsi,
                    }}
                  />
                </div>
              ))}
            </div>
        </div>
      </div>
    </section>
  )
}

AlbumTemplate.propTypes = {
  title: PropTypes.string,
  featuredimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
  caption: PropTypes.string,
  featuredpost: PropTypes.string,
  tanggal: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  album: PropTypes.shape({
    deskripsi: PropTypes.string,
    image: PropTypes.object,
  }),
}

const Album = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AlbumTemplate
        helmet={
          <Helmet titleTemplate="%s | Album">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.caption}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        caption={post.frontmatter.caption}
        album={post.frontmatter.album}
        image={post.frontmatter.album.image}
        deskripsi={post.frontmatter.album.deskripsi}
        tanggal={post.frontmatter.tanggal}
        categories={post.frontmatter.categories}
      />
    </Layout>
  )
}


Album.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default Album

export const albumPageQuery = graphql`
  query AlbumByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        featuredpost
        featuredimage {
            childImageSharp {
              fluid(maxWidth: 250, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        caption
        album {
          deskripsi
          image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
          }
        }
        categories {
              category
            }
        tanggal(formatString: "DD-MM-YYYY")
      }
    }
  }
`